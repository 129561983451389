
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import store from "@/store";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "search-modal",
  components: {},
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const searchModalRef = ref<null | HTMLElement>(null);

    const item = store.getters.getItem;
    
    const submit = () => {
      hideModal(searchModalRef.value);
    };

    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      item,
      submit,
      formRef,
      searchModalRef,
      translate
    };
  },
});
