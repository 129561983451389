

import axios from "axios";
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import store from "@/store";
import config from "@/core/config/UtilConfig";
import { Item } from "@/core/config/tables";
import supplyExternal from "../scripts/supplyExternal.js";
import { useI18n } from "vue-i18n";
import SearchModal from "@/components/modals/forms/SearchModal.vue";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "search-listing",
  components: {
    Datatable,
    SearchModal
  },
  setup() {
    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(translate("search"), []);
    });

    return { translate };
  },
  data(){
    return {
      items: Array<Item>(),
      key: "init",
      headers: config.itemHeaders,
      page: 1,
      pageSize: 20,
      keyword: "",
      field: config.itemSort,
      order: "asc"
    }
  },
  beforeMount(){
    this.getItems(this.field, this.order);
  },
  mounted(){
    window.addEventListener("locale-changed", (event) => {
      setCurrentPageBreadcrumbs(this.translate("search"), []);
      this.key = "lang"+localStorage.getItem("lang");
    });
    this.getNextItems();
  },
  methods: {
    getItems: function(field, order) {
      this.field = field;
      this.order = order;
      this.page = 1;
      supplyExternal.methods.getIP(function(ip){return})
      setTimeout(() => {
        const params = { params: {keyword: this.keyword, ip: store.getters.getIP, util: config.util, page: this.page, pageSize: this.pageSize, sort: this.field+":"+this.order} };
        axios.get(config.host+"/o/headless-supply-box/v1.0/items", params).
          then(response => {
            if(response.data.items[0]) {
              this.items = response.data.items;
              this.page += 1;
              this.key = "reloadItems"+this.keyword+this.field+this.order;
            } 
            else {
              this.items = Array<Item>();
              this.key = "empty";
            }
          }).catch(e => {
            this.items = Array<Item>();
            this.key = "empty";
          });
      }, 500);
    },
    getNextItems: function() {
      window.onscroll = () => {

        let bottomOfWindow = (window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight;

        if (bottomOfWindow) {
          supplyExternal.methods.getIP(function(ip){return})
          setTimeout(() => {
            const params = { params: {keyword: this.keyword, ip: store.getters.getIP, util: config.util, page: this.page, pageSize: this.pageSize, sort: this.field+":"+this.order} };
            axios.get(config.host+"/o/headless-supply-box/v1.0/items", params).
              then(response => {
                for(var i= 0; i<response.data.items.length; i++) {
                  this.items.push(response.data.items[i]);
                }
                this.key = "nextItems"+this.page;
              }).catch(e => console.log(e));
          }, 500);
          
          this.page += 1;
        }
      }
    },
    changeKeyword: function(e){
      this.keyword = e.target.value;
    },
    search: function(){
      this.getItems(this.field, this.order);
    },
    reset: function(){
      this.keyword = "";
      this.getItems(this.field, this.order);
    },
    setCurrentElement: async function(item) {
      store.dispatch(Actions.UPDATE_ITEM, item);
      
      this.key="updateItem"+item.id
      setTimeout(() => { document.getElementById("viewSearchModal")?.click(); }, 500);
      
    },
  }
});
